/**
 * Rule Access Mode
 */

import i18n from '../i18n';

const BADGE = 'BADGE';
const CLOSED = 'CLOSED';
const DEFAULT = 'DEFAULT';
const OPEN = 'OPEN';

const ruleAccessModes = [DEFAULT, BADGE, OPEN, CLOSED];

function getRuleAccessModeLabel(ruleAccessModeValue, quantity) {
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  if (ruleAccessModeValue === BADGE) return i18n.tc('ruleAccessMode.BADGE', qty);
  if (ruleAccessModeValue === CLOSED) return i18n.tc('ruleAccessMode.CLOSED', qty);
  if (ruleAccessModeValue === DEFAULT) return i18n.tc('ruleAccessMode.DEFAULT', qty);
  if (ruleAccessModeValue === OPEN) return i18n.tc('ruleAccessMode.OPEN', qty);
  return ruleAccessModeValue;
}

function getRuleAccessModeLabels(count) {
  const result = [];
  ruleAccessModes.forEach((mode) => {
    result.push(getRuleAccessModeLabel(mode, count));
  });
  return result;
}

function getRuleAccessModeReferences(quantity) {
  const result = [];
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  result.push({ value: BADGE, label: i18n.tc('ruleAccessMode.BADGE', qty) });
  result.push({ value: CLOSED, label: i18n.tc('ruleAccessMode.CLOSED', qty) });
  result.push({ value: DEFAULT, label: i18n.tc('ruleAccessMode.DEFAULT', qty) });
  result.push({ value: OPEN, label: i18n.tc('ruleAccessMode.OPEN', qty) });
  result.sort((a, b) => b.label < a.label);
  return result;
}

export default ruleAccessModes;
export {
  ruleAccessModes,
  BADGE,
  CLOSED,
  DEFAULT,
  OPEN,
  getRuleAccessModeReferences,
  getRuleAccessModeLabel,
  getRuleAccessModeLabels,
};
