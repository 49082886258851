/**
 * Item status values and labels
 */

import i18n from '../i18n';

const ITEM_STATUS_ACTIVE = 0;
const ITEM_STATUS_DEFAULT = ITEM_STATUS_ACTIVE; // onChange: update datatypes.itemStatusToBoolean()
const ITEM_STATUS_INACTIVE = 1;
const ITEM_STATUS_GET_ALL = -1;

const itemStatusValues = {
  active: ITEM_STATUS_ACTIVE,
  default: ITEM_STATUS_DEFAULT,
  inactive: ITEM_STATUS_INACTIVE,
  getAll: ITEM_STATUS_GET_ALL,
  getAllUrlParam: `itemStatus=${ITEM_STATUS_GET_ALL}`,
};

function getItemStatusLabel(itemStatusValue, quantity) {
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  if (itemStatusValue === ITEM_STATUS_ACTIVE) return i18n.tc('itemStatus.active', qty);
  if (itemStatusValue === ITEM_STATUS_DEFAULT) return i18n.tc('itemStatus.default', qty);
  if (itemStatusValue === ITEM_STATUS_INACTIVE) return i18n.tc('itemStatus.inactive', qty);
  if (itemStatusValue === ITEM_STATUS_GET_ALL) return i18n.tc('itemStatus.getAll', qty);
  return itemStatusValue;
}

function getItemStatusLabels(count) {
  const result = [];
  itemStatusValues.forEach((val) => {
    result.push(getItemStatusLabel(val, count));
  });
  return result;
}

function itemStatusFromBoolean(boolStatus) {
  return (boolStatus ? itemStatusValues.active : itemStatusValues.inactive);
}

function itemStatusToBoolean(itemStatus) {
  if (itemStatus === itemStatusValues.active) return true;
  if (itemStatus === itemStatusValues.default) return true;
  if (itemStatus === itemStatusValues.inactive) return false;
  if (itemStatus === itemStatusValues.getAll) {
    throw Error(i18n.t('errMsg.unexpectedConversionValue_fmt', {
      converter: 'itemStatusToBoolean',
      value: `${itemStatus} (${getItemStatusLabel(itemStatus)})`,
    }));
  }
  throw Error(i18n.t('errMsg.unexpectedConversionValue_fmt', {
    converter: 'itemStatusToBoolean', value: itemStatus,
  }));
}

function getItemStatusTypeReferences() {
  const result = [];
  result.push({
    value: itemStatusValues.active,
    label: getItemStatusLabel(itemStatusValues.active),
  });
  result.push({
    value: itemStatusValues.inactive,
    label: getItemStatusLabel(itemStatusValues.inactive),
  });
  return result;
}

export default itemStatusValues;
export {
  itemStatusValues,
  ITEM_STATUS_ACTIVE,
  ITEM_STATUS_DEFAULT,
  ITEM_STATUS_INACTIVE,
  ITEM_STATUS_GET_ALL,
  getItemStatusLabel,
  getItemStatusLabels,
  itemStatusFromBoolean,
  itemStatusToBoolean,
  getItemStatusTypeReferences,
};
