/**
 * Generic Values
 */

import i18n from '../i18n';

const SELECT_ALL_VALUE = '*';

const UNDEFINED_STARTTS = 0;
const UNDEFINED_STOPTS = 9876543210;

const genericValues = {
  selectAll: SELECT_ALL_VALUE,
  undefinedStartTs: UNDEFINED_STARTTS,
  undefinedStopTs: UNDEFINED_STOPTS,
};

function getGenericValueLabel(genericValue, quantity) {
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  if (genericValue === SELECT_ALL_VALUE) return i18n.tc('genericValue.SELECT_ALL', qty);
  if (genericValue === UNDEFINED_STARTTS) return i18n.tc('genericValue.UNDEFINED_STARTTS', qty);
  if (genericValue === UNDEFINED_STOPTS) return i18n.tc('genericValue.UNDEFINED_STOPTS', qty);
  return genericValue;
}

function getGenericValueLabels(count) {
  const result = [];
  genericValues.forEach((val) => {
    result.push(getGenericValueLabel(val, count));
  });
  return result;
}

export default genericValues;
export {
  genericValues,
  SELECT_ALL_VALUE,
  UNDEFINED_STARTTS,
  UNDEFINED_STOPTS,
  getGenericValueLabel,
  getGenericValueLabels,
};
