import { userRoles, getUserRoleLabels } from './userRole';
import { ruleAccessModes, getRuleAccessModeLabels } from './ruleAccessMode';
import { validateTimeStr } from './timeString';

const rules = {
  required: (value) => (!!value || value === 0) || 'Required.',
  requiredItems: (value) => (value?.length > 0) || 'Required items.',
  email: (value) => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || 'Invalid e-mail.';
  },
  number: (value) => {
    const pattern = /^\d+$/;
    return pattern.test(value) || 'Must be a number.';
  },
  isIPv4Address: (value) => {
    const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return pattern.test(value) || 'Invalid IP address.';
  },
  isMacAddress: (value) => {
    if (!value) return true;
    return !!(value?.length === 12) || 'Mac Address should be 12 characters long (lower case, no field separator).';
  },
  isUserRole: (value) => !!(userRoles.indexOf(value) > -1) || `Must be in: ${getUserRoleLabels()}`,
  isRuleAccessMode: (value) => !!(ruleAccessModes.indexOf(value) > -1) || `Must be in: ${getRuleAccessModeLabels()}`,
  isTimeString: (value) => !!(validateTimeStr(value).length === 5) || `Unrecognized time format ${value}`,
  isJson: (value) => {
    if (value === '') return true;
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return 'Invalid JSON format.';
    }
  },
};

export default rules;
