import i18n from '../i18n';
import {
  genericValues,
  UNDEFINED_STARTTS,
  UNDEFINED_STOPTS,
  getGenericValueLabel,
} from './genericValues';

const baseDatatypes = {
  boolean: 'boolean',
  integer: 'integer',
  itemStatus: 'itemStatus',
  json: 'json',
  string: 'string',
  timestamp: 'timestamp',
  stringArray: 'stringArray',
};

const dayDurationTimestamp = 24 * 60 * 60 - 1;

function getTemplateFieldType(datatype) {
  // conversions from internal or DB data type to JS/Vue template type value.
  if (datatype === baseDatatypes.boolean) { return 'boolean'; }
  if (datatype === baseDatatypes.integer) { return 'number'; }
  if (datatype === baseDatatypes.itemStatus) { return 'number'; }
  if (datatype === baseDatatypes.string) { return 'text'; }
  if (datatype === baseDatatypes.json) { return 'text'; }
  // below are not a javascript data types. We use them as idenfifiers for specific treatments.
  if (datatype === baseDatatypes.timestamp) { return 'timestamp'; }
  if (datatype === baseDatatypes.stringArray) { return 'stringArray'; }
  return 'text';
}

function getBooleanTypeReferences() {
  const result = [];
  result.push({ value: true, label: i18n.t('datatype.boolean.true') });
  result.push({ value: false, label: i18n.t('datatype.boolean.false') });
  return result;
}

function getBooleanLabel(boolValue) {
  if (boolValue === true) return i18n.t('datatype.boolean.true');
  if (boolValue === false) return i18n.t('datatype.boolean.false');
  throw TypeError(`Boolean value expected, read ${boolValue}.`);
}

function getBooleanActiveLabel(boolValue) {
  if (boolValue === true) return i18n.t('datatype.boolean.active');
  if (boolValue === false) return i18n.t('datatype.boolean.inactive');
  throw TypeError(`Boolean value expected, read ${boolValue}.`);
}

function getSelectSummaryLabel(selectArray) {
  if (selectArray && selectArray?.length === 1 && selectArray[0] === genericValues.selectAll) {
    return i18n.tc('datatype.select.all', 0);
  }
  if (selectArray && selectArray?.length) {
    return i18n.t('datatype.select.selectionCount_fmt', { count: selectArray.length });
  }
  return 'ERROR !!!';
}

function getTimestampLabel(timestampValue) {
  if (timestampValue === UNDEFINED_STARTTS) {
    return getGenericValueLabel(UNDEFINED_STARTTS);
  }
  if (timestampValue === UNDEFINED_STOPTS) {
    return getGenericValueLabel(UNDEFINED_STOPTS);
  }
  return new Date(timestampValue * 1000).toLocaleString('fr-FR');
}

function isoDateStrToTimeStamp(isoDateStr) {
  const wrkDate = Date.parse(isoDateStr);
  const timezoneOffset = new Date().getTimezoneOffset() * 60;
  const result = wrkDate / 1000 + timezoneOffset;
  return result;
}

function strDateDDMMYYYYToYYYYMMDD(date) {
  if (!date) return null;
  const [day, month, year] = date.split('/');
  const result = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  return result;
}

function strDateYYYYMMDDToDDMMYYYY(date) {
  if (!date) return null;
  const [year, month, day] = date.split('-');
  const result = `${day}/${month}/${year}`;
  return result;
}

function strDateYYYYMMDDToTimestamp(isoDateStr) {
  return isoDateStrToTimeStamp(isoDateStr);
}

function strDateYYYYMMDDToEndOfDayTimestamp(isoDateStr) {
  const morning = isoDateStrToTimeStamp(isoDateStr);
  const result = morning + dayDurationTimestamp;
  return result;
}

function timestampToYYYYMMDDStrDate(timestamp) {
  if (timestamp === UNDEFINED_STOPTS || timestamp === UNDEFINED_STARTTS || !timestamp) {
    return null;
  }
  const timezoneOffset = new Date().getTimezoneOffset() * 60;
  const result = new Date((timestamp - timezoneOffset) * 1000).toISOString();
  return result.substr(0, 10);
}

function getDoorCapacityLabel(value, qty = 1) {
  if (value === 0) return i18n.tc('door.capacityValue.0', qty);
  if (value === 1) return i18n.tc('door.capacityValue.1', qty);
  throw Error(i18n.t('errMsg.unexpectedConversionValue_fmt', {
    converter: 'getDoorCapacityLabel', value,
  }));
}

function getDoorCapacityReferences() {
  const result = [];
  result.push({ value: 0, label: getDoorCapacityLabel(0) });
  result.push({ value: 1, label: getDoorCapacityLabel(1) });
  return result;
}

/* Internal pseudo-datatypes used to define data structures (aka. store metadata within data).
*/
const reservedDatatypes = {
  // array of field definitions, used in form sub sections
  fieldArray: 'fieldArray',
  // identify specific object used to define relation in which the "field" owner is involved
  relationDefinition: 'relationDefinition',
};

const datatypes = {
  ...baseDatatypes,
  ...reservedDatatypes,
};

export default datatypes;
export {
  datatypes,
  baseDatatypes,
  reservedDatatypes,
  getBooleanLabel,
  getBooleanActiveLabel,
  getBooleanTypeReferences,
  getSelectSummaryLabel,
  getTemplateFieldType,
  getTimestampLabel,
  isoDateStrToTimeStamp,
  strDateDDMMYYYYToYYYYMMDD,
  strDateYYYYMMDDToDDMMYYYY,
  strDateYYYYMMDDToEndOfDayTimestamp,
  strDateYYYYMMDDToTimestamp,
  timestampToYYYYMMDDStrDate,
  getDoorCapacityReferences,
  getDoorCapacityLabel,
};
