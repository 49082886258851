/**
 * time string management
 */

function validateTimeStr(timeStr) {
  // type and length validation
  if (typeof timeStr !== 'string') throw Error('timeStr value must be a string !');
  if (!timeStr.length > 0) throw Error('Required timeStr value must be provided!');
  // local initializations
  let separator = null;
  const digits = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
  // get the provided separator (not a digit)
  for (let index = 0; index < timeStr.length; index += 1) {
    const char = timeStr.charAt(index);
    if (digits.indexOf(char) < 0) {
      separator = char;
      break;
    }
  }
  // decompose input weither we found a separator (or not)
  let hour = 0;
  let min = 0;
  if (separator) {
    const splitted = timeStr.split(separator);
    hour = timeStr.indexOf(separator) === 0 ? 0 : parseInt(splitted[0], 10);
    min = splitted.length > 1 && splitted[1] ? parseInt(splitted[1], 10) : 0;
  } else if (timeStr.length === 4) {
    hour = parseInt(timeStr.substring(0, 2), 10);
    min = parseInt(timeStr.substring(2), 10);
  } else {
    hour = parseInt(timeStr, 10);
  }
  // Decoded values validation
  if ((!hour && (hour !== 0)) || (!min && min !== 0)) throw Error(`${timeStr} : unrecognized time format.`);
  if (hour > 24) throw Error(`${timeStr} : hours value should be in range [0,24].`);
  if (min > 59) throw Error(`${timeStr} : minutes value should be in range [0,59].`);
  if (hour === 24 && min !== 0) throw Error(`${timeStr} : day time cannot exceed 24:00.`);
  // Format result as 'HH:NN'
  return `${String(hour).padStart(2, '0')}:${String(min).padStart(2, '0')}`;
}

export default validateTimeStr;
export {
  validateTimeStr,
};
