/**
 * User Role (ADMIN)
 */

import i18n from '../i18n';

const ADMIN = 'ADMIN';
const GUEST = 'GUEST';
const INHABITANT = 'HABITANT';

const userRoles = [ADMIN, GUEST, INHABITANT];

function getUserRoleLabel(userRoleValue, quantity) {
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  if (userRoleValue === INHABITANT) return i18n.tc('userRole.inhabitant', qty);
  if (userRoleValue === GUEST) return i18n.tc('userRole.guest', qty);
  if (userRoleValue === ADMIN) return i18n.tc('userRole.admin', qty);
  return userRoleValue;
  // throw TypeError(`Expected user Role value, read ${userRoleValue}.`);
}

function getUserRoleLabels(count) {
  const result = [];
  userRoles.forEach((role) => {
    result.push(getUserRoleLabel(role, count));
  });
  return result;
}

function getUserRoleReferences(quantity) {
  const result = [];
  const qty = (typeof quantity !== 'undefined') ? quantity : 1;
  result.push({ value: ADMIN, label: i18n.tc('userRole.admin', qty) });
  result.push({ value: GUEST, label: i18n.tc('userRole.guest', qty) });
  result.push({ value: INHABITANT, label: i18n.tc('userRole.inhabitant', qty) });
  result.sort((a, b) => b.label < a.label);
  return result;
}

export default userRoles;
export {
  userRoles,
  INHABITANT,
  ADMIN,
  getUserRoleReferences,
  getUserRoleLabel,
  getUserRoleLabels,
};
